"use client";

import { useRefresh } from "@/components/hooks/window";
import { stringToDateRange } from "@/lib/utils";
import { getCookie, hasCookie, setCookie } from "cookies-next";
import { subDays } from "date-fns";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { Aggregation, Hit } from "./ai/type";

const prod: boolean = process.env.NODE_ENV === "production";

export const defaultSearchOptions: SearchOptions = {
  ticker: "",
  dateRange: {
    from: subDays(new Date(), 90),
    to: new Date(),
  },
  dateRangeString: "3 Months",
  switches: {
    forums: true,
    research: true,
    blogs: true,
    transcripts: true,
    details: true,
  },
  maxSearchResults: "50",
  maxSummaryItems: "0",
  showGroupSummary: false,
  maxFragments: "3",
  fragmentSize: "500",
  systemPrompt:
    "You will act as an advisor to a financial portfolio manager who invests in global equity markets. Use the provided text to answer question. Use numbers where applicable",
  prompt: "",
  summarizer_name: prod ? "GPT3.5" : "mock",
  order_by: "submitDate",
  question: "",
};

export type SearchOptions = {
  ticker: string;
  dateRange: DateRange | undefined;
  dateRangeString?: string;
  switches: {
    forums: boolean;
    research: boolean;
    blogs: boolean;
    transcripts: boolean;
    details: boolean;
  };
  maxSearchResults: string;
  maxSummaryItems: string;
  showGroupSummary: boolean;
  maxFragments: string;
  fragmentSize: string;
  systemPrompt: string;
  prompt: string;
  summarizer_name: "GPT3.5" | "mock";
  order_by?: "submitDate" | "_score";
  question?: string;
};

type OptionsContextType = {
  searchText: string;
  setSearchText: (searchText: string) => void;
  searchOptions: SearchOptions;
  setSearchOptions: (searchOptions: SearchOptions) => void;
  searchResults: Hit[] | undefined;
  setSearchResults: (results: Hit[] | undefined) => void;
  groupSummaryItems: { id: number; type: string }[];
  setGroupSummaryItems: (
    items: {
      id: number;
      type: string;
    }[],
  ) => void;
  groupSummaryResult: string | undefined;
  setGroupSummaryResult: (result: string | undefined) => void;
  loadingResults: boolean;
  setLoadingResults: (loading: boolean) => void;
  params: any;
  setParams: (params: any) => void;
  aggregations: Aggregation[] | undefined;
  setAggregations: (aggregations: Aggregation[] | undefined) => void;
  itemCount: number;
  setItemCount: (count: number) => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  session: any;
};

export const OptionsContext = createContext<OptionsContextType>({
  searchText: "",
  setSearchText: () => {},
  searchOptions: defaultSearchOptions,
  setSearchOptions: () => {},
  searchResults: undefined,
  setSearchResults: () => {},
  groupSummaryItems: [],
  setGroupSummaryItems: () => {},
  groupSummaryResult: undefined,
  setGroupSummaryResult: () => {},
  loadingResults: false,
  setLoadingResults: () => {},
  params: {},
  setParams: () => {},
  aggregations: undefined,
  setAggregations: () => {},
  itemCount: 0,
  setItemCount: () => {},
  drawerOpen: false,
  setDrawerOpen: () => {},
  session: null,
});

async function getUserPreferences() {
  let options;

  if (hasCookie(`user-options`)) {
    options = getCookie(`phg-user-options`);
    console.log("getUserPreferences: phg-user-options ", options);
  }

  options = await fetch("/api/user", {
    method: "GET",
    cache: "default",
  });

  if (options.ok) {
    const response = await options.json();
    setCookie(`phg-user-options`, JSON.stringify(response), {
      maxAge: 60 * 60 * 2,
    });
    return response as SearchOptions;
  }

  return defaultSearchOptions;
}

export const AppStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const router = useRouter();
  const [searchText, setSearchText] = useState<string>("");
  const [searchOptions, setSearchOptions] =
    useState<SearchOptions>(defaultSearchOptions);

  const [searchResults, setSearchResults] = useState<Hit[]>();
  const [aggregations, setAggregations] = useState<Aggregation[]>();
  const [itemCount, setItemCount] = useState<number>(0);

  const [groupSummaryItems, setGroupSummaryItems] = useState<
    {
      id: number;
      type: string;
    }[]
  >([]);
  const [groupSummaryResult, setGroupSummaryResult] = useState<string>();
  const [loadingResults, setLoadingResults] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [params, setParams] = useState<any>({});
  const { status, data: session } = useSession();

  // Calls the Shareholder site to keep the token fresh
  useRefresh();

  const fetchPreferences = useCallback(async () => {
    const response = await getUserPreferences();
    setSearchOptions((prevOptions) => ({
      ...prevOptions,
      ...response,
      dateRange: stringToDateRange(response?.dateRangeString ?? "3 Months"),
      // ticker: "",
      summarizer_name: "GPT3.5",
      prompt: "",
      question: "",
    }));
  }, []);

  useEffect(() => {
    if (status === "unauthenticated") {
      return;
    }

    if (status === "loading") {
      return;
    }

    if (status === "authenticated") {
      fetchPreferences();
    }
  }, [status, session, router, fetchPreferences]);

  if (status === "loading") {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center space-y-2">
        <p className="text-slate-500">Loading...</p>
      </div>
    );
  }

  return (
    <OptionsContext.Provider
      value={{
        searchText,
        setSearchText,
        searchOptions,
        setSearchOptions,
        searchResults,
        setSearchResults,
        groupSummaryItems,
        setGroupSummaryItems,
        groupSummaryResult,
        setGroupSummaryResult,
        loadingResults,
        setLoadingResults,
        params,
        setParams,
        aggregations,
        setAggregations,
        itemCount,
        setItemCount,
        drawerOpen,
        setDrawerOpen,
        session,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};
